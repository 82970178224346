import React from "react";
import parse, { domToReact } from "html-react-parser";

const NextNewInsertAd = ({ index, isMobile, dataURL, imageAdsInserted, currentPage, article }) => { 

  const options = {
    replace: ({ name, type, children }) => {  
      
      //Check for p Element or child type text and the text length > 100 
      if (name !== 'p' || children?.type !== 'text' && children.data?.length > 100) {
        return;
      }

      // Check for ad being inserted already
      if (!imageAdsInserted) {
        imageAdsInserted = true;
        return (
          <>
            <p>{domToReact( children, options )}</p>
            <div className="code-block code-block--MPU">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="MPUCenterRight" className="ad-container"/>
            </div>
          </>
        );
      }

      // If all else Fails then don't change node
      return;
    },
    trim: true,
  };

  return (
		<div data-url={dataURL} data-index={index} style={{display: index === currentPage ? "block" : "none"}}>
			{parse(article, options)}
		</div>
	);
};

export default NextNewInsertAd;