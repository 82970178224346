import React, { useEffect, useState, useContext } from "react";
import { graphql, Script } from "gatsby";
import _ from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
//import parse from "html-react-parser";
import { IntlProvider, FormattedMessage } from "react-intl";

import Layout from "../components/layout";
import GoogleAds from "../components/google-ads";
import SeoStructure from "../components/seo-structure";
import Avantis from "../components/vendor/Avantis";

import TaboolaWidget from "../components/vendor/TaboolaWidget";
import { fetchHeaderBids } from "../components/header-bidding"
import NextCheeseInsertAd from "../components/adunits/NextCheeseInsertAd";
import LatestPosts from "../components/LatestPosts";
import RandomPosts from "../components/RandomPosts";
import HeaderScripts from "../components/HeadScripts";

import { isMobilePredicate, loadScript, isBrowser } from "../utils";
import useTaboola from "../hooks/useTaboola";
import { defaultLanguage } from "../i18n";
import TrackingContext from "../context/TrackingContext";
import LocalesContext from "../context/LocalesContext";

export function Head() {
  return (
    <HeaderScripts />
  )
}

// Flag to insert ads just for first image

function BlogPost({ data, pageContext }) {
  const post = data.posts.nodes[0];
  const latestPosts = data.latestPosts.nodes;
  const randomPosts = data.postsByRandom.nodes;

  const [isMobile, setIsMobile] = useState(false);
  const [windowOrigin, setWindowOrigin] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const { utm_source } = useContext(TrackingContext);

  const { setupTaboola } = useTaboola(1);
  const locale =
  pageContext.locale !== defaultLanguage ? `/${pageContext.locale}` : "";
  
  let imageAdsInserted = false;
  
  useEffect(() => {

    setIsMobile(isMobilePredicate());

    setWindowOrigin(window.location.origin);

    setCurrentPage(0);

    /* Google Adsense */
    loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1473855894054853', true, true );
  }, []);

  // Setup Taboola Web Push
  useEffect(() => {
    setupTaboola();
  }, [setupTaboola]);

  //Set Next Page
  const handleNextPage = () => {
    setCurrentPage( currentPage + 1 );

    window.googletag.cmd.push(function() {
      let gptSlots = window.googletag.pubads().getSlots();
      gptSlots.forEach(function(adUnit) {
        adUnit.setTargeting('currentPageTC', currentPage + 2 );
      });
    });
    
    fetchHeaderBids( window.apsAdUnits, window.pbjsAdUnits, 2300);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  }
  //Set Prev Page
  const handlePrevPage = () => {
    setCurrentPage( currentPage - 1 );

    window.googletag.cmd.push(function() {
      let gptSlots = window.googletag.pubads().getSlots();
      gptSlots.forEach(function(adUnit) {
        adUnit.setTargeting('currentPageTC', currentPage );
      });
    });

    fetchHeaderBids( window.apsAdunits, window.pbjsAdUnits, 2300);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (_.isEmpty(post)) return null;

  const canonicalSlug = `/${pageContext.originalSlug}`;

  return (
    <>
      <Script src={process.env.GATSBY_PUBSTACK_TAG}/>
      <GoogleAds
        type="next_cheese"
        page={pageContext.page}
        lang={pageContext.locale}
      />
      <Seo post={post} />

      <SeoStructure
        title={post.seo.title ? post.seo.title : post.title}
        description={post.seo.metaDesc ? post.seo.metaDesc : ''}
        slug={`${pageContext.locale}${canonicalSlug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date)}`,
            dateModified: `${new Date(post?.modified)}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${!process.env.GATSBY_SITE_NAME ? "" : process.env.GATSBY_SITE_NAME}`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
      <section className="code-block code-block--top-banner">
        <div className="divider">
          <span>Advertisement</span>
        </div>

        {/* Google Adsense */}
        <div id="LeaderAboveTitle" className="ad-container"  />
      </section>
      <article className="post">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post.author.node.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          <div className="code-block code-block--banner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div id="LeaderBelowTitle" className="ad-container"
            />
          </div>

          <Avantis mobileId='124' desktopId='124' />

          <div className="post-content">
            {post.nextPageTC.map((article, index) => {
              const dataURL = `${windowOrigin}${locale}/${pageContext.originalSlug}/${index + 1 === 1 ? "" : index + 1}`;
              imageAdsInserted = ( index === 0 ) ? false : true;
              return (
                <NextCheeseInsertAd
                  key={index}
                  index={index}
                  isMobile={isMobile}
                  dataURL= {dataURL}
                  imageAdsInserted={imageAdsInserted}
                  currentPage= {currentPage}
                  article= {article}
                />
              )
            })}
          </div>

          <div className="code-block-next">

          <div className="code-block code-block--MPU">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="MPUBelowNext" className="ad-container"/>
            </div>

            <div className="paggination-buttons">
              <ul className="paggination-buttons__wrapper">
                {currentPage !== 0 && (
                  <li className="prev">
                    <button
                      onClick={() => {
                        handlePrevPage()
                        if (window.assertive) {
                          window.assertive.nextPage();
                        }
                      }}
                    >
                      ⟵
                    </button>
                  </li>
                )}
                {currentPage !== (post.nextPageTC.length - 1) && (
                <li className="next">
                  <button
                    onClick={() => {
                      handleNextPage()
                      if (window.assertive) {
                        window.assertive.nextPage();
                      }
                    }}
                  >
                    <FormattedMessage id="next" defaultMessage="Next" />
                  </button>
                </li>
                )}
              </ul>
            </div>
          </div>
          {isBrowser &&
            <TaboolaWidget
              mode="thumbnails-d"
              container="taboola-below-paging-buttons"
              placement="Cheese Feed Below Next"
              utm={utm_source}
            />
          }
        </div>

        <aside className="post-ads-sidebar col-1">
          <LatestPosts data={latestPosts} />
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="LeftColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
          <RandomPosts data={randomPosts} />
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>
      </article>
    </>
  );
}

const BlogPosts = ({ data, pageContext }) => {
  const avaiablePostTranslations = data.avaiablePostTranslations.nodes;
  const avaiableLangs = avaiablePostTranslations.map(
    (post) => post.language.slug
  );

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.originalSlug,
          avaiableLangs,
          pageNumber: pageContext.page,
        }}
      >
        <Layout containerClasses="container--next-new">
          <BlogPost data={data} pageContext={pageContext} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!, $originalSlug: String!, $defaultCategorySlugs: [String], $locale: String, $orderBy: [SortOrderEnum], $randNumber: Int!) {
    avaiablePostTranslations: allWpPost(
      filter: { originalSlug: { eq: $originalSlug } }
    ) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(
      filter: { slug: { eq: $slug } }
    ) {
      nodes {
        id
        title
        content
        nextPageTC
        slug
        excerpt
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            link
            height
            width
          }
        }
        modified(formatString: "MMMM D, YYYY")
        seo {
          title
          metaDesc
          focuskw
          metaRobotsNoindex
          metaRobotsNofollow
          metaKeywords
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
            height
            width
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        date(formatString: "MMMM D, YYYY")
      }
    }
    latestPosts: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: $defaultCategorySlugs } } }
        },
        language: {
          slug: { eq: $locale }
        }
      },
      sort: {fields: date, order: DESC}, limit: 5
    ) {
      nodes {
        id
        slug
        title
        featuredImage {
          node {
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
            }
          }
        }
      }
    }
    postsByRandom: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: $defaultCategorySlugs } } }
        },
        language: {
          slug: { eq: $locale }
        }   
      },
      sort: {fields: date, order: $orderBy}, skip: $randNumber, limit: 5 
    ) {
      nodes {
        id
        slug
        title
        featuredImage {
          node {
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;