import React from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";

function RandomPosts( posts ) {
    const intl = useIntl();

    return (
        <>
            <section id="random-posts">
                <h3>Random Posts</h3>
                { posts.data.map((post) => {
                    let featuredImage = post.featuredImage?.node.mediaDetails.filteredSizes.filter(
                        (img) => img.name === "medium"
                      );
                        return (
                            <div key={post.id} className="random-posts__post">
                                <div className="random-posts__img">
                                    <Link to={`/${intl.locale}/` + post.slug + '/'}>
                                        <img
                                            src={featuredImage ? featuredImage[0]?.sourceUrl : 'null'}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="random-posts__title"><Link to={`/${intl.locale}/` + post.slug + '/'}>{post.title}</Link></div>
                            </div>
                        )
                    })
                }
            </section>
           
        </>
    );
      
}
export default RandomPosts